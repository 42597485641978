import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
}))

export default function Impressum() {
  const classes = useStyles()

  const history = useHistory()
  function handleClick() {
    history.push(`/`)
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            <ArrowBackIcon onClick={handleClick} />

            <Typography variant="h6" className={classes.title}>
              Impressum
            </Typography>
          </Toolbar>
        </AppBar>
      </div>

      <div className="centering impress-margin">
        <p>Manachlux UG (haftungsbeschränkt)</p>{' '}
        <p>Besselstr. 25, 68219 Mannheim</p>
        <p>
          Eingetragen im Handelsregister - Registergericht: Mannheim,
          Registernummer HRB 733705
        </p>
        <p>Vertreten durch Geschäftsführer David Sheikh</p>{' '}
        <p>Umsatzsteuer-Identifikationsnummer: DE325044873</p>{' '}
        <p>Telefon: 0621 97688197</p>
        <p>Email: manachlux@gmail.com</p>
        <p>Verantwortlich für den Inhalt nach §55 Abs 2 RStV: David Sheikh</p>
        <p>
          Die Europäische Kommission stellt eine Online-Plattform zur
          Streitbeilegung bereit, die Sie unter{' '}
          <span>
            <a
              href="https://ec.europa.eu/consumers/odr"
              target="manachlux.com"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr
            </a>
          </span>{' '}
          finden. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor
          einer Verbraucherschlichtungsstelle nicht verpflichtet und nicht
          bereit.
        </p>
      </div>
    </Fragment>
  )
}
