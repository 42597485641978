import React, { useState } from 'react'
import AppContext from './AppContext'

const AllProv = ({ children }) => {
  const [correctLogin, setCorrectLogin] = useState(false)
  const [open, setOpen] = useState(false)
  const [loginP, setLoginP] = useState('start')
  const [usr, setUsr] = useState('start')

  const context = {
    correctLogin,
    setCorrectLogin,
    open,
    setOpen,
    loginP,
    setLoginP,
    usr,
    setUsr,
  }

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}

export default AllProv
