import React, { useContext } from 'react'
import LoginComp from './LoginComp'
import AppContext from '../providers/AppContext'
import EmailEingabe from './EmailEingabe'
import Sicherheitsfrage from './Sicherheitsfrage'
import Hacked from './Hacked'
import { useHistory } from 'react-router-dom'

export default function Start() {
  const history = useHistory()
  function handleClick() {
    history.push('/impressum')
  }
  const { correctLogin, setCorrectLogin, open, loginP } = useContext(AppContext)
  return (
    <div className="centering centerios anders">
      <h3>
        Willkommen bei <span className="gumbl">GUMBL</span>
        <span className="mail">Mail</span>.{' '}
      </h3>{' '}
      <h4>Ihrem sicheren Email-Provider.</h4>{' '}
      {!open ? (
        loginP === 'start' ? (
          <div>
            <LoginComp />
            <p onClick={handleClick} className="fixim">
              Impressum
            </p>
          </div>
        ) : loginP === 'weiter' ? (
          <EmailEingabe />
        ) : (
          <Sicherheitsfrage />
        )
      ) : (
        <Hacked />
      )}
    </div>
  )
}
