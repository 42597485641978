import React, { Fragment, useState, useContext } from 'react'
import AppContext from '../providers/AppContext'
import Sicherheitsfrage from './Sicherheitsfrage'

export default function LoginComp() {
  const { setCorrectLogin, open, setOpen, setLoginP, setUsr } = useContext(
    AppContext,
  )
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wrongEP, setWrongEP] = useState(false)

  //   const [versuchText, setVersuchText] = useState(true);
  const [countDown, setCountDown] = useState(3)
  const [valida, setValida] = useState('')

  const register = () => {
    if (email === 'Karle') {
      setValida('')
      setEmail('')
    } else {
      setValida(
        'Falsch! Bitte geben Sie die korrekte Antwort auf die von Ihnen festgelegte Sicherheitsfrage ein.',
      )
      if (countDown > 1) {
        setCountDown(countDown - 1)
      } else {
        setCountDown(3)
      }
    }
  }

  const versuchForm =
    countDown > 1
      ? `Sie haben noch ${countDown} Versuche`
      : 'Dies ist Ihr letzter Versuch!'

  const login = () => {
    if (
      (password === 'money' || password === 'Money') &&
      (email === 'alexk@gumblmail.de' ||
        email === 'Alexk@gumblmail.de' ||
        email === 'aIexk@gumblmail.de')
    ) {
      setWrongEP(false)
      setCorrectLogin(true)
      setOpen(true)
      setUsr('alex')
    } else if (
      (password === 'reiten123' || password === 'Reiten123') &&
      (email === 'michaelamau@gumblmail.de' ||
        email === 'Michaelamau@gumblmail.de')
    ) {
      setWrongEP(false)
      setCorrectLogin(true)
      setOpen(true)
      setUsr('michaela')
    } else if (
      (password === 'bitcoin' || password === 'Bitcoin') &&
      (email === 'simon-kaeufer@gumblmail.de' ||
        email === 'Simon-kaeufer@gumblmail.de' ||
        email === 'Simon-Kaeufer@gumblmail.de' ||
        email === 'simon-käufer@gumblmail.de')
    ) {
      setWrongEP(false)
      setCorrectLogin(true)
      setOpen(true)
      setUsr('simon')
    } else if (
      (password === 'tillstetten' || password === 'Tillstetten') &&
      (email === 'tillst-autoteile@gumblmail.de' ||
        email === 'Tillst-autoteile@gumblmail.de' ||
        email === 'Tillst-Autoteile@gumblmail.de')
    ) {
      setWrongEP(false)
      setCorrectLogin(true)
      setOpen(true)
      setUsr('tillst')
    } else {
      setWrongEP(true)
    }
  }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  function handleReg() {
    setLoginP('weiter')
  }

  function handleLog() {
    setOpen(true)
  }

  return (
    <Fragment>
      <div>
        <div>
          <h3 onClick={() => setOpen(!open)}>Login</h3>
        </div>
        <div className="ten-margin">
          <input
            className="inputb ten-margin"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
          />{' '}
          <br />
          <input
            className="inputb ten-margin"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
          />
          <br />
          <button className="ten-margin" onClick={login}>
            Login
          </button>
          <br />
          {wrongEP ? (
            <p className="valida">
              Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben
            </p>
          ) : null}
          <span className="unterstrich" onClick={handleReg}>
            Passwort vergessen?{' '}
          </span>
        </div>
      </div>
    </Fragment>
  )
}
