import React, { useState, useContext } from "react";
import Badge from "@material-ui/core/Badge";
import AppContext from "../providers/AppContext";
import Posteingang from "./Posteingang";

export default function Hacked() {
  const [post, setPost] = useState("posteingang");

  const {} = useContext(AppContext);

  const messages = [
    [
      "Restaurant DaVinci",
      "Re: Reservierung",
      "15.01.2021 20:54 Uhr",
      "Hallo Herr Käufer,",
      "als wir gerade Ihren Tisch abgeräumt haben, haben wir gesehen, dass Ihre Begleitung eine rote Lesebrille vergessen hat. Wenn Sie mir Ihre Adresse schreiben, schicke ich Ihnen die Brille gerne zu.",
      "Mit freundlichen Grüßen Ihr Ristaurant DaVinci",
      [
        "Ursprüngliche Nachricht",
        "Absender: Alexander Käufer",
        "Empfänger: Restaurant DaVinci",
        "Datum: 13.01.2021 10:24 Uhr",
        "Sehr geehrte Damen und Herren,",
        "ich möchte gerne einen Tisch für den 15.01.2021 um 19:30 Uhr für ein Geschäftsessen für 2 Personen reservieren.",
        "Mit freundlichen Grüßen Alexander Käufer",
      ],
    ],
    [
      "Post Services GmbH",
      "Bestätigung Nachsendeauftrag",
      "15.12.2020 8:33 Uhr",
      "Hallo Herr Käufer,",
      "hiermit bestätigen wir Ihnen den Nachsendeauftrag für Ihre Post ab dem 15.12.2020 wegen Umzugs. Wir senden Ihre Post ab dann an Ihre neue Adresse Hauptstraße 14, 12345 Hohenstedten",
      "Ihr Kundenservice Post Services GmbH",
    ],
    [
      "Johannes Kahrs",
      "Hier ist was tolles los",
      "16.01.2021",
      "Hier steppt grad der Bär, ich rufe Dich nacher mal an und erzähle Dir davon",
    ],
  ];

  return (
    <div>
      <div className="orders-filter card-title-class ">
        <span
          onClick={() => setPost("posteingang")}
          className={post === "posteingang" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={0} color="secondary">
            Posteingang
          </Badge>
        </span>
        <span
          onClick={() => setPost("postausgang")}
          className={post === "postausgang" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={0} color="secondary">
            Gesendet
          </Badge>
        </span>
      </div>

      {messages.map((school, index) => (
        <Posteingang message={school} />
      ))}
    </div>
  );
}
