import React, { Fragment, useState, useContext } from 'react'
import AppContext from '../providers/AppContext'

export default function SicherTillst() {
  const { setLoginP } = useContext(AppContext)
  const [email, setEmail] = useState('')

  const [reg, setReg] = useState(false)
  //   const [versuchText, setVersuchText] = useState(true);
  const [countDown, setCountDown] = useState(3)
  const [valida, setValida] = useState('')

  const register = () => {
    if (email === '290') {
      setReg(true)
      setValida('')
      setEmail('')
    } else {
      setValida(
        'Falsch! Bitte geben Sie die korrekte Antwort auf die von Ihnen festgelegte Sicherheitsfrage ein.',
      )
      if (countDown > 1) {
        setCountDown(countDown - 1)
      } else {
        setCountDown(3)
      }
    }
  }

  const versuchForm =
    countDown > 1
      ? `Sie haben noch ${countDown} Versuche`
      : 'Dies ist Ihr letzter Versuch!'

  function handleLog() {
    setLoginP('start')
    setReg(false)
  }

  return (
    <Fragment>
      <div className="anders">
        <hr />
        <h3 className="ten-margin">Sicherheitsfrage</h3>

        <div className="ten-margin">
          {reg ? (
            <div>
              <p className="korrekt">Die Antwort ist korrekt!</p>
              <p>
                Ihr Passwort lautet{' '}
                <span className="underly">"tillstetten"</span>
              </p>
            </div>
          ) : (
            <div>
              <div className="sfragen">
                <p>
                  Die letzten 3 Ziffern des Nummernschildes unseres einzigen
                  Geschäftsautos?
                </p>
              </div>
              <input
                className="inputb ten-margin antwort"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Die von Ihnen festgelegte Antwort"
              />{' '}
              <br />
              <p className="valida">{valida}</p>
              {reg ? null : <p>{versuchForm}</p>}
              <button className="ten-margin" onClick={register}>
                Absenden
              </button>
            </div>
          )}
          <p>
            Zurück zum{' '}
            <span className="unterstrich" onClick={handleLog}>
              Login
            </span>
          </p>
        </div>
      </div>
    </Fragment>
  )
}
