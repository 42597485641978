import React, { Fragment } from 'react'
import TabComp from '../components/TabComp'

export default function Main() {
  return (
    <Fragment>
      <TabComp />
    </Fragment>
  )
}
