import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}))

export default function MichaelaPost(props) {
  const classes = useStyles()

  const [auf, setAuf] = useState(false)

  return (
    <div className={classes.root}>
      <Grid container spacing={1} onClick={() => setAuf(!auf)}>
        <Grid item xs={4}>
          <div className="mail-title">
            <span>{props.message[0]}</span>
          </div>
        </Grid>
        <Grid item xs>
          <div className="kursiv mail-title">
            <span>{props.message[1]}</span>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="mail-title">
            <span> {props.message[2]}</span>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} onClick={() => setAuf(!auf)}>
        <Grid item xs={4}>
          <div className="mail-title">
            {!auf ? (
              <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
            ) : (
              <div className="viz">
                <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs>
          <div className="kursiv mail-title">
            <span> </span>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="mail-title">
            <span>{props.message[3]}</span>
          </div>
        </Grid>
      </Grid>
      {auf ? (
        <div className="allergene">
          <div>
            <p>{props.message[4]}</p>
            <p>{props.message[5]}</p>
            <p>
              {' '}
              {props.message[0] === 'Nico Neumann' ? (
                <div>
                  <span> {props.message[6]}</span>{' '}
                  <a
                    href=" https://tillst.web.app/ "
                    target="tillst.web.app"
                    rel="noopener noreferrer"
                  >
                    https://tillst.web.app/
                  </a>
                </div>
              ) : (
                <span> {props.message[6]}</span>
              )}
            </p>
          </div>
          {props.message.length > 7 ? (
            <div className="antwort">
              <p>
                <span className="billard">{props.message[7][0]}</span>
                <br />
                {props.message[7][1]}
                <br />
                {props.message[7][2]}
                <br />
                {props.message[7][3]}
              </p>
              <p>{props.message[7][4]}</p>
              <p>{props.message[7][5]}</p>
              <p>{props.message[7][6]}</p>
            </div>
          ) : null}
        </div>
      ) : null}
      <hr />
    </div>
  )
}
