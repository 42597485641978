import React, { Fragment, useState, useContext } from 'react'
import AppContext from '../providers/AppContext'
import Sicherheitsfrage from './Sicherheitsfrage'

export default function EmailEingabe() {
  const { setLoginP, setUsr } = useContext(AppContext)
  const [email, setEmail] = useState('')

  const [wrongEP, setWrongEP] = useState(true)

  const weiter = () => {
    if (
      email === 'alexk@gumblmail.de' ||
      email === 'Alexk@gumblmail.de' ||
      email === 'aIexk@gumblmail.de'
    ) {
      setWrongEP(true)
      setEmail('')
      setLoginP('anders')
      setUsr('alex')
    } else if (
      email === 'simon-kaeufer@gumblmail.de' ||
      email === 'Simon-kaeufer@gumblmail.de' ||
      email === 'Simon-Kaeufer@gumblmail.de' ||
      email === 'simon-käufer@gumblmail.de'
    ) {
      setWrongEP(true)
      setEmail('')
      setLoginP('anders')
      setUsr('simon')
    } else if (
      email === 'michaelamau@gumblmail.de' ||
      email === 'Michaelamau@gumblmail.de'
    ) {
      setWrongEP(true)
      setEmail('')
      setLoginP('anders')
      setUsr('michaela')
    } else if (
      email === 'tillst-autoteile@gumblmail.de' ||
      email === 'Tillst-autoteile@gumblmail.de' ||
      email === 'Tillst-Autoteile@gumblmail.de'
    ) {
      setWrongEP(true)
      setEmail('')
      setLoginP('anders')
      setUsr('tillst')
    } else {
      setWrongEP(false)
    }
  }

  function handleLog() {
    setLoginP('start')
  }

  return (
    <Fragment>
      <div>
        <div>
          <h3>Passwort vergessen: bitte geben Sie Ihre Email-Adresse ein.</h3>
        </div>
        <div className="ten-margin">
          <input
            className="inputb ten-margin"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
          />{' '}
          <br />
          <button className="ten-margin margin-around" onClick={handleLog}>
            Zurück
          </button>
          <button className="ten-margin margin-around" onClick={weiter}>
            Weiter
          </button>
          <br />
          {!wrongEP ? (
            <p className="valida">
              Dieses Email-Konto existiert nicht. Bitte überprüfen Sie die
              Schreibweise.
            </p>
          ) : null}
        </div>
      </div>
    </Fragment>
  )
}
