import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Main from "./pages/main";
import Impressum from "./pages/impressum";
import AllProv from "./providers/AllProv";

function App() {
  return (
    <>
      <div>
        <AllProv>
          <Router>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/impressum" component={Impressum} />
            </Switch>
          </Router>
        </AllProv>
      </div>
    </>
  );
}

export default App;
