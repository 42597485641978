import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AppContext from '../providers/AppContext'
import Gesendet from './Gesendet'
import AltPosteingang from './AltPosteingang'
import MichaelaGes from './MichaelaGes'
import MichaelaPost from './MichaelaPost'
import SimonGes from './SimonGes'
import SimonPost from './SimonPost'
import TillstGes from './TillstGes'
import TillstPost from './TillstPost'
import AltGesendet from './AltGesendet'

import Start from './Start'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function TabComp() {
  const { open, setOpen, usr } = useContext(AppContext)
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [schreiv, setSchreiv] = useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const michaelames = [
    [
      'Carolin Käufer',
      'Re: Tolle Gelegenheit',
      '29.10.2019',
      '18:05 Uhr',
      'Hi Michaela,',
      'das ist ja Fantastisch! Das ist natürlich schon deutlich (DEUTLICH hahaha) mehr, als ich bzw. Alexander eigentlich ausgeben wollte. Aber er hatte mir versprochen mir ein Pferd zu schenken....ich werde ihn schon weich kochen. Also ich bin definitiv interessiert! Lass mal nachher telefonieren. Ich will mir das Pferd natürlich möglichst bald ansehen. Das ist aufregend!',
      'LG Carolin',
    ],
    [
      'Inkasso Tillstetten GmbH',
      'Letzte Zahlungsaufforderung',
      '28.10.2019',
      '13:02 Uhr',
      'Sehr geehrte Frau Maurer,',
      'dies ist Ihre letzte Gelegenheit mit uns eine gütliche Einigung zu erzielen. Mit Gebühren sind Sie Schuldnerin eines Betrags in Höhe von 101.091.- Euro. Wir erwarten die Zahlung des vollständigen Betrags bis spätestens 28.11.2019 als Eingang auf unserem Konto. Sollte die Frist verstreichen, werden wir einen Gerichtsvollzieher beauftragen und behalten uns weitere Zwangsmaßnahmen vor.',
      'Inkasso Tillstetten GmbH',
    ],
  ]

  const michaelasent = [
    [
      'Carolin Käufer',
      'Tolle Gelegenheit',
      '29.10.2019',
      '12:09 Uhr',
      'Hallo Carolin,',
      'du bist doch noch auf der Suche nach einem neuen Pferd...ich habe durch persönliche Kontakte das perfekte Pferd gefunden! Es ist ein Hengst (genau wie Du wolltest!) von einem der berühmtesten Gestüte Deutschlands. Der Preis dürfte so um die 120.000 Euro sein, aber das ist es definitiv Wert (für ein Pferd aus so einer tollen Linie ist das sehr günstig)! Wenn Du willst, kann ich auch mit Alexander sprechen um ihn zu überzeugen. Wir Mädels müssen zusammenhalten ;)',
      'Was meinst Du dazu? LG Michaela',
    ],
  ]

  const tillstmes = [
    [
      'Erik Lindt',
      'Krank morgen',
      '01.02.2021',
      '19:52 Uhr',
      'Hallo Nico,',
      'mir gehts grad total schlecht. Ich schätze mal Grippe oder so. Ich geh gleich morgen früh zum Arzt. Werde also morgen definitiv krank sein und nicht zur Arbeit kommen. Melde mich nochmal telefonisch wenn ich beim Arzt war.',
      'Mit freundlichen Grüßen Erik',
    ],
  ]

  const tillstsent = [
    [
      'Erik Lindt',
      'Re: Krank morgen',
      '02.02.2021',
      '6:50 Uhr',
      'Hallo Erik,',
      'alles klar. Dann werde ich die Lieferungen mit unserem Lieferwagen heute Vormittag in Grabenbruck selbst übernehmen.',
      'Gute Besserung! Nico',
    ],
  ]

  const simonmes = [
    [
      'Golfplatz Tillstetten',
      'Re: Frage Anfängerkurs',
      '05.01.2021',
      '11:14 Uhr',
      'Sehr geehrter Herr Käufer,',
      'die Anmeldung zum Einsteigerkurs ist jederzeit möglich. Sie brauchen lediglich eine Golfausrüstung und passende Kleidung. Anfängern empfehlen wir ein Golfset für den Einstieg zu kaufen. Verbindlich anmelden können Sie sich ab Ende Februar.',
      'Mit freundlichen Grüßen Siegrid Blume',
    ],
  ]

  const simonsent = [
    [
      'Golfplatz Tillstetten',
      'Frage Anfängerkurs',
      '05.01.2021',
      '8:59 Uhr',
      'Sehr geehrte Damen und Herren,',
      'ich würde gerne Golf spielen lernen, und möchte fragen, welche Voraussetzungen bestehen, um bei Ihnen einen Einführungskurs machen zu können.',
      'Freundliche Grüße Simon Käufer',
    ],
    [
      'Simon Käufer',
      'heute Abend...',
      '11.01.2021',
      '12:59 Uhr',
      'Hallo Papa,',
      'ich habe Dir mal wieder die Nussriegel SweetUnder bestellt, die Du so liebst. Ich komme heute Abend kurz bei Dir zu Hause vorbei und bringe sie Dir. :) Bis dann...',
      'Simon',
    ],
    [
      'Simon Käufer',
      'Was bedeutet das?',
      '13.01.2021',
      '20:50 Uhr',
      'Hallo Papa,',
      'ich erreiche dich telefonisch nicht. Hast du gewusst, dass in unseren Werkstätten fehlerhafte Ersatzteile verbaut werden? Wenn die Staatsanwaltschaft die Ermittlungen wieder aufrollt, kommen womöglich hohe Schadenersatzkosten und Strafen auf uns zu. Das gefährdet das Unternehmen. Ich bin dir dankbar, dass du mich zum Geschäftsführer gemacht hast, denn das wollte ich ja unbedingt, aber das gibt dir nicht das Recht unser Familienunternehmen zu ruinieren! Dazu darf es nicht kommen. Alles steht und fällt damit, dass wir erneute Ermittlungen verhindern (falls du darin verwickelt sein solltest). Ich muss deshalb wissen was Sache ist!',
      'Melde dich bitte schnellstmöglich bei mir… Simon.',
    ],
  ]

  const messages = [
    [
      'Markus Mink',
      'Geschäftskontakte',
      '12.04.2019',
      '8:50 Uhr',
      'Hallo Alexander,',
      'soweit ich weiß, bestellst Du manchmal Ersatzteile bei der TillSt Autoteile GmbH. In letzter Zeit ist mir zu Ohren gekommen, dass der Besitzer und Geschäftsführer eine sehr zwielichtige Person ist. Angeblich saß er schon wegen schwerer Straftaten im Gefängnis. Ich habe da ein ganz schlechtes Gefühl, und fände es besser, wenn unser Unternehmen keine Geschäfte mehr mit ihm macht. Denke es ist wichtig, dass Du das weißt.',
      'Freundliche Grüße Markus',
    ],
    [
      'Nico Neumann',
      'Einladung',
      '19.12.2019',
      '18:20 Uhr',
      'Hallo Alexander,',
      'ich habe gehört, Du sagst bald wegen eines Unfalls vor Gericht aus. Darüber sollten wir beide uns mal persönlich unterhalten. Melde Dich, oder komm spontan bei mir im Geschäft vorbei. Die Kontaktdaten findest Du auf unserer Homepage',
    ],

    [
      'Simon Käufer',
      'heute Abend...',
      '11.01.2021',
      '12:59 Uhr',
      'Hallo Papa,',
      'ich habe Dir mal wieder die Nussriegel SweetUnder bestellt, die Du so liebst. Ich komme heute Abend kurz bei Dir zu Hause vorbei und bringe sie Dir. :) Bis dann...',
      'Simon',
    ],
    [
      'Simon Käufer',
      'Was bedeutet das?',
      '13.01.2021',
      '20:50 Uhr',
      'Hallo Papa,',
      'ich erreiche dich telefonisch nicht. Hast du gewusst, dass in unseren Werkstätten fehlerhafte Ersatzteile verbaut werden? Wenn die Staatsanwaltschaft die Ermittlungen wieder aufrollt, kommen womöglich hohe Schadenersatzkosten und Strafen auf uns zu. Das gefährdet das Unternehmen. Ich bin dir dankbar, dass du mich zum Geschäftsführer gemacht hast, denn das wollte ich ja unbedingt, aber das gibt dir nicht das Recht unser Familienunternehmen zu ruinieren! Dazu darf es nicht kommen. Alles steht und fällt damit, dass wir erneute Ermittlungen verhindern (falls du darin verwickelt sein solltest). Ich muss deshalb wissen was Sache ist!',
      'Melde dich bitte schnellstmöglich bei mir… Simon.',
    ],

    [
      'Notar Dr. Walter Rot',
      'Bestätigung Termin',
      '25.01.2020',
      '8:33 Uhr',
      'Hallo Herr Alexander Käufer,',
      'hiermit bestätige ich Ihnen den Termin am 02.02.2021 um 09:00 Uhr zur Änderung des Gesellschaftervertrages zwecks Änderung des Geschäftsführers.',
    ],
    [
      'Markus Mink',
      'Wo bleibst Du?',
      '02.02.2020',
      '09:14 Uhr',
      'Hallo Alexander,',
      'wir sitzen hier seit 15 Minuten beim Notar...Theresa ist auch hier. Kann Dich auf dem Handy nicht erreichen. Wir warten alle! Was ist los? Wie lange brauchst Du noch? Melde Dich bitte',
    ],
  ]

  const sent = [
    [
      'Markus Mink',
      'Hab nachgedacht',
      '25.01.2021',
      '14:11 Uhr',
      'Hallo Markus',
      'ich weiß, dass Du sehr wütend warst als ich Dich nicht zum Geschäftsführer meiner Werkstattkette gemacht habe. Mittlerweile denke ich, dass die Entscheidung Simon zum Geschäftsführer gemacht zu haben falsch war, weil Simon noch nicht so weit ist Geschäftsführer zu sein. Ich habe mich deshalb dazu entschlossen, Dich zum Geschäftsführer zu machen (falls Du einverstanden bist natürlich). Ich habe einen Termin beim Notar Dr. Walter Rot für den 02.02. um 09:00 Uhr ausgemacht, um Dich als Geschäftsführer einzusetzen. Ich hätte Dich gerne dabei.',
      'Freundliche Grüße Alexander',
    ],
  ]

  const serverproblem =
    'Aufgrund von Serverproblemen können momentan keine Emails geschrieben werden. Wir arbeiten mit Hochdruck an der Behebung des Problems.'

  function schreiben() {
    if (schreiv === '') {
      setSchreiv(serverproblem)
    } else {
      setSchreiv('')
    }
  }

  return !open ? (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{ background: '#ffc000' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          centered
        >
          <Tab label="Start" {...a11yProps(0)} style={{ color: '#538135' }} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Start />
      </TabPanel>
    </div>
  ) : (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{ background: '#ffc000' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          centered
        >
          <Tab
            label="Gesendet"
            {...a11yProps(0)}
            style={{ color: '#538135' }}
          />
          <Tab
            label="Posteingang"
            {...a11yProps(1)}
            style={{ color: '#538135' }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="centering centerios">
          <button onClick={schreiben}>Email schreiben</button>
          <p className="valida">{schreiv}</p>
          <button onClick={() => setOpen(false)}>Ausloggen</button>
          <div>
            <div className="ueberschrift">
              <span className="margir">An</span>
              <span>Betreff</span>
              <span>Datum</span>
            </div>
            {usr === 'alex' ? (
              <div>
                {sent.map((school, index) => (
                  <AltGesendet sent={school} />
                ))}
              </div>
            ) : usr === 'simon' ? (
              <div>
                {simonsent.map((school, index) => (
                  <SimonGes sent={school} />
                ))}
              </div>
            ) : usr === 'michaela' ? (
              <div>
                {michaelasent.map((school, index) => (
                  <MichaelaGes sent={school} />
                ))}
              </div>
            ) : usr === 'tillst' ? (
              <div>
                {tillstsent.map((school, index) => (
                  <TillstGes sent={school} />
                ))}
              </div>
            ) : (
              <p>Upps...es gab einen Fehler.</p>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="centering centerios">
          <button onClick={schreiben}>Email schreiben</button>
          <p className="valida">{schreiv}</p>
          <button onClick={() => setOpen(false)}>Ausloggen</button>
          <div>
            <div className="ueberschrift">
              <span className="margir">Von</span>
              <span>Betreff</span>
              <span>Datum</span>
            </div>
            {usr === 'alex' ? (
              <div>
                {messages.map((school, index) => (
                  <AltPosteingang message={school} />
                ))}
              </div>
            ) : usr === 'simon' ? (
              <div>
                {simonmes.map((school, index) => (
                  <SimonPost message={school} />
                ))}
              </div>
            ) : usr === 'michaela' ? (
              <div>
                {michaelames.map((school, index) => (
                  <MichaelaPost message={school} />
                ))}
              </div>
            ) : usr === 'tillst' ? (
              <div>
                {tillstmes.map((school, index) => (
                  <TillstPost message={school} />
                ))}
              </div>
            ) : (
              <p>Upps...es gab einen Fehler.</p>
            )}
          </div>
        </div>
      </TabPanel>
    </div>
  )
}
