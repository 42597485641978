import React, { useState } from "react";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function Posteingang(props) {
  const [auf, setAuf] = useState(false);
  return (
    <div>
      {" "}
      <div onClick={() => setAuf(!auf)} className="mail-title">
        <div>
          {!auf ? (
            <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
          ) : <div className="viz"><ArrowForwardIosIcon fontSize="inherit" color="disabled" /></div>}
        </div>
        <div>{props.message[0]}</div>

        <div className="kursiv">{props.message[1]}</div>
        <div>
          <span>{props.message[2]}</span>
          <br />
          <span>{props.message[3]}</span>
        </div>
      </div>
      {auf ? (
        <div className="allergene">
          <div>
            <p>{props.message[4]}</p>
            <p>{props.message[5]}</p>
            <p> {props.message[0] === "Nico Neumann" ?
              (<div><span> {props.message[6]}</span> <a
                href=" https://tillst.web.app/ "
                target="tillst.web.app"
                rel="noopener noreferrer"
              >
                https://tillst.web.app/
          </a></div>) : <span> {props.message[6]}</span>}
            </p>

          </div>
          {props.message.length > 7 ? (
            <div className="antwort">
              <p>
                <span className="billard">{props.message[7][0]}</span>
                <br />
                {props.message[7][1]}
                <br />
                {props.message[7][2]}
                <br />
                {props.message[7][3]}
              </p>
              <p>{props.message[7][4]}</p>
              <p>{props.message[7][5]}</p>
              <p>{props.message[7][6]}</p>
            </div>
          ) : null}
        </div>
      ) : null}
      <hr />
    </div>
  );
}
