import React, { Fragment, useState, useContext } from 'react'
import AppContext from '../providers/AppContext'
import LoginComp from './LoginComp'

export default function SicherAlex() {
  const { setCorrectLogin, open, setOpen, setLoginP } = useContext(AppContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wrongEP, setWrongEP] = useState(false)

  const [reg, setReg] = useState(false)
  //   const [versuchText, setVersuchText] = useState(true);
  const [countDown, setCountDown] = useState(3)
  const [valida, setValida] = useState('')

  const register = () => {
    if (email === 'PAM' || email === 'pam' || email === 'Pam') {
      setReg(true)
      setValida('')
      setEmail('')
    } else {
      setValida(
        'Falsch! Bitte geben Sie die korrekte Antwort auf die von Ihnen festgelegte Sicherheitsfrage ein.',
      )
      if (countDown > 1) {
        setCountDown(countDown - 1)
      } else {
        setCountDown(3)
      }
    }
  }

  const versuchForm =
    countDown > 1
      ? `Sie haben noch ${countDown} Versuche`
      : 'Dies ist Ihr letzter Versuch!'

  const login = () => {
    if (password !== 'money' || email !== 'alexk@gumblmail.de') {
      setWrongEP(true)
    } else {
      setWrongEP(false)
      setCorrectLogin(true)
      setOpen(false)
    }
  }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  function handleReg() {
    setReg(true)
    setOpen(!open)
  }

  function handleLog() {
    setLoginP('start')
    setReg(false)
  }

  return (
    <Fragment>
      <div className="anders">
        <hr />
        <h3 className="ten-margin">Sicherheitsfrage</h3>

        <div className="ten-margin">
          {reg ? (
            <div>
              <p className="korrekt">Die Antwort ist korrekt!</p>
              <p>
                Ihr Passwort lautet <span className="underly">"money"</span>
              </p>
            </div>
          ) : (
            <div>
              <div className="sfragen">
                <p>
                  - Anfangsbuchstabe von Michaelas Alias-Name im Spiel
                  "Flottfight"{' '}
                </p>
                <p>
                  - Anfangsbuchstabe des Namens des Vereinsheims des FC
                  Grabenbruck.
                </p>
                <p>
                  - Anfangsbuchstabe des Vornamens meines besten Mitarbeiters
                </p>
              </div>
              <input
                className="inputb ten-margin antwort"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Die von Ihnen festgelegte Antwort"
              />{' '}
              <br />
              <p className="valida">{valida}</p>
              {reg ? null : <p>{versuchForm}</p>}
              <button className="ten-margin" onClick={register}>
                Absenden
              </button>
            </div>
          )}
          <p>
            Zurück zum{' '}
            <span className="unterstrich" onClick={handleLog}>
              Login
            </span>
          </p>
        </div>
      </div>
    </Fragment>
  )
}
