import React, { Fragment, useState, useContext } from 'react'
import AppContext from '../providers/AppContext'
import SicherAlex from './SicherAlex'
import SicherMichaela from './SicherMichaela'
import SicherSimon from './SicherSimon'
import SicherTillst from './SicherTillst'

export default function Sicherheitsfrage() {
  const { usr } = useContext(AppContext)
  return (
    <Fragment>
      {usr === 'alex' ? (
        <SicherAlex />
      ) : usr === 'simon' ? (
        <SicherSimon />
      ) : usr === 'michaela' ? (
        <SicherMichaela />
      ) : usr === 'tillst' ? (
        <SicherTillst />
      ) : (
        <p>Upps...etwas ist schiefgelaufen.</p>
      )}
    </Fragment>
  )
}
